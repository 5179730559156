<template>
  <v-card class="rounded-0">
    <v-card-title class="rounded-0" style="background-color: #4f585e; color: white">Mitarbeiter bearbeiten</v-card-title>
    <v-card-text>
      <v-select placeholder="Anrede" :items="salutations.elements" v-model="salutationId" item-text="translated.displayName" item-value="id" />
      <v-text-field v-model="firstName" placeholder="Vorname"></v-text-field>
      <v-text-field v-model="lastName" placeholder="Nachname"></v-text-field>
      <v-text-field v-model="email" placeholder="Email"></v-text-field>
      <v-text-field v-model="personnelNumber" placeholder="Personalnummer"></v-text-field>
      <v-text-field v-model="defaultBillingAddress.street" placeholder="Straße und Hausnummer"></v-text-field>
      <v-text-field v-model="defaultBillingAddress.zipcode" placeholder="Postleitzahl"></v-text-field>
      <v-text-field v-model="defaultBillingAddress.city" placeholder="Ort"></v-text-field>
    </v-card-text>
    <v-card-text>
      Versandadresse
      <v-text-field v-model="defaultShippingAddress.street" placeholder="Straße und Hausnummer"></v-text-field>
      <v-text-field v-model="defaultShippingAddress.zipcode" placeholder="Postleitzahl"></v-text-field>
      <v-text-field v-model="defaultShippingAddress.city" placeholder="Ort"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn tile
             color="primary"
             dark
             large
             bottom
             class="mb-n6"
             @click.stop="handleUpdateProfileClick()">Speichern</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import EmployeeService from "@/modules/employee/services/employee.service";

export default {
  props: ["employee"],

  data: () => ({
    dialog: true,
    id: null,
    guest: false,
    salutationId: null,
    firstName: null,
    lastName: null,
    email: null,
    customerExtensionId: null,
    personnelNumber: null,
    defaultBillingAddressId: null,
    defaultBillingAddress: {
      city: null,
      street: null,
      zipcode: null,
    },
    defaultShippingAddress: {
      city: null,
      street: null,
      zipcode: null,
    },
    defaultShippingAddressId: null,
  }),

  computed: {
    ...mapGetters("employee", ["salutations", "addresses"])
  },

  watch: {
    employee(value) {
      this.handleSetFields(value);
    }
  },

  created() {
    this.handleSetFields(this.employee);
  },

  methods: {
    handleSetFields(employee) {
      this.id = employee.id;
      this.salutationId = employee.salutationId;
      this.firstName = employee.firstName;
      this.lastName = employee.lastName;
      this.email = employee.email;
      this.customerExtensionId = employee.extensions.customerExtension ? employee.extensions.customerExtension._uniqueIdentifier : null;
      this.personnelNumber = employee.extensions.customerExtension ? employee.extensions.customerExtension.personnelNumber : null;
      this.defaultBillingAddressId = employee.defaultBillingAddressId;
      this.defaultBillingAddress.city = employee.defaultBillingAddress.city;
      this.defaultBillingAddress.street = employee.defaultBillingAddress.street;
      this.defaultBillingAddress.zipcode = employee.defaultBillingAddress.zipcode;
      this.defaultShippingAddressId= employee.defaultShippingAddressId;
      this.defaultShippingAddress.city = employee.defaultShippingAddress.city;
      this.defaultShippingAddress.street = employee.defaultShippingAddress.street;
      this.defaultShippingAddress.zipcode = employee.defaultShippingAddress.zipcode;
    },

    async handleUpdateProfileClick() {
      try {
        await EmployeeService.updateProfile(
            {
              id: this.id,
              salutationId: this.salutationId,
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              customerExtensionId: this.customerExtensionId,
              personnelNumber: this.personnelNumber,
              defaultBillingAddressId: this.defaultBillingAddressId,
              defaultBillingAddress: { ...this.defaultBillingAddress },
              defaultShippingAddressId: this.defaultShippingAddressId,
              defaultShippingAddress: { ...this.defaultShippingAddress },
            }
        );

        this.$emit("afterUpdatedEmployee");
      } catch (e) {
        console.error(e);
      }
    },
  }
}
</script>