<template>
  <v-container fluid>
    <v-card elevation="0" class="rounded-0">
      <v-card-title>
        <v-spacer />
        <v-btn tile color="primary" elevation="5" :disabled="loading" @click.stop="registerDialog = !registerDialog">Neuer Mitarbeiter</v-btn>
      </v-card-title>

      <v-data-table
          :headers="headers"
          :items="employeeList"
          :items-per-page="5"
          @click:row="showDialog"
          :loading="loading"
      >
        <template v-slot:item.active="props">
          <v-icon color="green lighten-2" v-if="props.item.active">mdi-check</v-icon>
          <v-icon color="red lighten-2" v-else>mdi-close</v-icon>
        </template>

        <template v-slot:item.actions="props">
          <v-btn elevation="0" color="primary" tile x-small v-if="!props.item.active" @click="handleActivateClick(props.item.id)" :disabled="loading">Aktivieren</v-btn>
          <v-btn elevation="0" color="primary" tile x-small v-else @click="handleDeactivateClick(props.item.id)" :disabled="loading">Deaktivieren</v-btn>
          <v-btn icon class="ml-5" @click.stop="handleEditEmployeeClick(props.item)" :disabled="loading">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="registerDialog" class="rounded-0" max-width="500">
      <Register @afterAddedEmployee="handleAfterAddedEmployeeEvent" />
    </v-dialog>

    <v-dialog v-model="updateProfileDialog" class="rounded-0" max-width="500">
      <UpdateEmployeeProfile @afterUpdatedEmployee="handleAfterUpdatedEmployeeEvent" :employee.sync="employee" />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Register from "@/modules/employee/components/Register";
import EmployeeService from "@/modules/employee/services/employee.service";
import UpdateEmployeeProfile from "@/modules/employee/components/UpdateEmployeeProfile";

export default {
  components: {UpdateEmployeeProfile, Register},

  data: () => ({
    registerDialog: false,
    updateProfileDialog: false,
    employee: null,
    headers: [
      {
        text: "Aktiv",
        align: "start",
        sortable: true,
        value: "active"
      },
      {
        text: "Personalnummer",
        align: "start",
        sortable: false,
        value: "extensions.customerExtension.personnelNumber"
      },
      {
        text: "Vorname",
        align: "start",
        sortable: true,
        value: "firstName"
      },
      {
        text: "Nachname",
        align: "start",
        sortable: true,
        value: "lastName"
      },
      {
        text: "Bestellungen",
        align: "end",
        sortable: true,
        value: "orderCount"
      },
      {
        text: "",
        align: "end",
        sortable: true,
        value: "actions"
      }
    ],
  }),

  mounted() {
    this.initEmployees()
  },

  computed: {
    ...mapGetters("employee", ["employees", "salutations", "loading"]),

    employeeList: function() {
      return this.employees?.elements;
    }
  },

  methods: {
    ...mapActions("employee", ["list", "getSalutationList", "getAddressList"]),

    initEmployees() {
      this.list();
      this.getSalutationList();
      this.getAddressList();
    },

    handleEditEmployeeClick(employee) {
      this.employee = employee;
      this.updateProfileDialog = true;
    },

    handleAfterAddedEmployeeEvent() {
      this.list();
      this.registerDialog = false;
    },

    handleAfterUpdatedEmployeeEvent() {
      this.list();
      this.updateProfileDialog = false;
    },

    async handleActivateClick(id) {
      let me = this;

      try {
        await EmployeeService.activate({"customerId": id});

        me.list();
      } catch (e) {
        console.error(e);
      }
    },

    async handleDeactivateClick(id) {
      let me = this;

      try {
        await EmployeeService.deactivate({"customerId": id});

        me.list();
      } catch (e) {
        console.error(e);
      }
    },

    showDialog(val) {
      console.log("Show Dialog", val.id);
    }
  }
}
</script>
