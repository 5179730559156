<template>
  <v-card class="rounded-0">
    <v-card-title class="rounded-0" style="background-color: #4f585e; color: white">Neuer Mitarbeiter</v-card-title>
    <v-card-text>
      <v-select placeholder="Anrede" :items="salutations.elements" v-model="salutationId" item-text="translated.displayName" item-value="id" />
      <v-text-field v-model="firstName" placeholder="Vorname"></v-text-field>
      <v-text-field v-model="lastName" placeholder="Nachname"></v-text-field>
      <v-text-field v-model="personnelNumber" placeholder="Personalnummer"></v-text-field>
      <v-text-field v-model="email" placeholder="Email"></v-text-field>
      <v-text-field type="password" v-model="password" placeholder="Passwort"></v-text-field>
      <v-divider />
      <v-text-field v-model="street" placeholder="Straße u. Hausnummer"></v-text-field>
      <v-text-field v-model="zipcode" placeholder="Postleitzahl"></v-text-field>
      <v-text-field v-model="city" placeholder="Ort"></v-text-field>
      <v-divider />
      <v-combobox placeholder="Lieferadresse" :items="addresses.elements" v-model="shippingAddress" item-text="lastName" item-value="id" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn tile
             color="primary"
             dark
             large
             bottom
             class="mb-n6"
             @click.stop="handleRegisterClick()">Speichern</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeeService from "@/modules/employee/services/employee.service";

export default {
  data: () => ({
    dialog: true,
    guest: false,
    salutationId: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    street: null,
    zipcode: null,
    city: null,
    countryId: null,
    shippingAddress: null,
    personnelNumber: null
  }),

  computed: {
    ...mapGetters("employee", ["salutations", "addresses"])
  },

  methods: {
    async handleRegisterClick() {
      try {
        await EmployeeService.register(
            {
              salutationId: this.salutationId,
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              password: this.password,
              groupId: this.groupId,
              personnelNumber: this.personnelNumber,
              billingAddress: {
                street: this.street,
                zipcode: this.zipcode,
                city: this.city,
                countryId: ""
              },
              shippingAddress: { ... this.shippingAddress }
            }
        );

        this.$emit("afterAddedEmployee");
      } catch (e) {
        console.error(e);
      }
    },
  }
}
</script>

<style lang="scss">
.v-dialog--active {
  border-radius: 0;
  overflow: inherit;
}

.v-dialog.v-dialog--active {
  overflow: inherit;
}

</style>